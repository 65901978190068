<template>
  <div class="article">
    <div class="actionBar">
      <a-space>
        <CompanySelect
          @CompanySelect="CompanySelect"
          :serchKeyword="undefined"
          :selectId="listParams.company_id"
          style="width:200px"
        ></CompanySelect>
        <GroupSelect @GroupSelect="GroupSelect"></GroupSelect>
        <a-range-picker
          style="width: 280px"
          v-model="time"
          format="YYYY-MM-DD"
          valueFormat="YYYY-MM-DD"
          :locale="locale"
          @change="timeChange"
        >
        </a-range-picker>

        <a-button
          icon="download"
          type="primary"
          @click="downloadR"
          v-if="handLists.top.indexOf('export') != -1"
        >
          导出结果</a-button
        >
      </a-space>
    </div>
    <a-table
      :columns="columns"
      :data-source="data"
      rowKey="id"
      :pagination="{
        total: count,
        current: listParams.page,
        pageSize: listParams.limit,
        showTotal: (total) => `共 ${total} 条`,
      }"
      @change="pageChange"
    >
      <span slot="action" slot-scope="text, record">
        <a-popconfirm
          title="确定删除？"
          ok-text="确定"
          cancel-text="取消"
          @confirm="confirm(record)"
          v-if="handLists.right.indexOf('del') != -1"
        >
          <FontIcon title="删除" type="icon-lajitong" size="22"></FontIcon>
        </a-popconfirm>
      </span>
    </a-table>
  </div>
</template>

<script>
import { visitBackIndex, visitBackDelete } from "@/apis/index";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import moment from "moment";
import { watch } from "fs";
import local from "@/utils/local";
import aaa from "@/config/config";
const columns = [
  {
    title: "拜访名称",
    dataIndex: "visit",
    scopedSlots: { customRender: "visit" },
    customRender: (text, record) => {
      return text.title;
    },
  },
  {
    title: "医生",
    dataIndex: "user",
    scopedSlots: { customRender: "users" },
    customRender: (text, record) => {
      return text.user_realname ? text.user_realname : text.user_nickname;
    },
  },

  {
    title: "反馈内容",
    dataIndex: "content",
    scopedSlots: { customRender: "content" },
  },

  {
    title: "反馈时间",
    dataIndex: "create_time",
    scopedSlots: { customRender: "create_time" },
    customRender: (text, record) => {
      if (text > 0) {
        return moment(Number(text + "000")).format("YYYY-MM-DD");
      } else {
        return "";
      }
    },
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  data() {
    return {
      locale,
      columns,
      data: [],
      listParams: {
        page: 1,
        limit: 10,
        visit_id: 0,
        group_id: undefined,
        start_time: "",
        end_time: "",
        company_id: undefined,
      },
      count: 0,
      time: [],
      handLists: this.$store.state.menuHandleList,
    };
  },
  computed: {
    handList() {
      return this.$store.state.menuHandleList;
    },
  },
  watch: {
    handList(val) {
      this.handLists.top = val.top ? val.top : [];
      this.handLists.right = val.right ? val.right : [];
    },
  },
  created() {
    this.getVisitBackIndex();
  },
  methods: {
    downloadR() {
      const token = local.get("adminToken");
      window.location.href = `${aaa.url}/manage/v1/visitBack/exportResult?company_id=${this.listParams.company_id}&start_time=${this.listParams.start_time}&end_time=${this.listParams.end_time}&visit_id=${this.listParams.visit_id}&group_id=${this.listParams.group_id}&token=${token}`;
    },
    CompanySelect(val) {
      this.listParams.page = 1;
      this.listParams.company_id = val;
      this.getVisitBackIndex();
    },
    timeChange(a) {
      if (a.length > 0) {
        this.listParams.start_time = a[0];
        this.listParams.end_time = a[1];
      } else {
        this.listParams.start_time = "";
        this.listParams.end_time = "";
      }
      this.listParams.page = 1;
      this.getVisitBackIndex();
    },
    GroupSelect(val) {
      this.listParams.page = 1;
      this.listParams.group_id = val;
      this.getVisitBackIndex();
    },
    confirm(rec) {
      visitBackDelete({ id: rec.id }).then((res) => {
        if (res.code == 1) {
          this.$message.success("删除成功");
          this.getVisitBackIndex();
        }
      });
    },
    pageChange(a) {
      this.listParams.page = a.current;
      this.getVisitBackIndex();
    },
    getVisitBackIndex() {
      visitBackIndex(this.listParams).then((res) => {
        
        if (res.data.list.length == 0 && this.listParams.page > 1) {
          this.listParams.page--;
          this.getVisitBackIndex();
        } else {
          this.data = res.data.list;
          this.count = res.data.count;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.article {
  .actionBar {
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
  }
}
</style>
